<template>
  <div class="backgroundStyle">
    <!-- NavBar-->
    <navbar-vue />
    <!-- Pill -->
    <pill></pill>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue";
import NavbarVue from "../home/components/Navbar.vue";
import Pill from "./Pill.vue";

export default {
  components: {
    BImg,
    NavbarVue,
    Pill,
  },

};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
.backgroundStyle {
  background-color: #8ea2b486 !important;
}
</style>
